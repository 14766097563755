import React, { FC } from "react";

export const Logo = () => {
  return (
    <div className="flex gap-4 items-center justify-center cursor-default select-none relative">
      <div className="h-10 w-10">
      <svg viewBox="0 0 266 259" className="h-full w-full">
      <g clip-path="url(#clip0_6_3)">
        <path d="M250.734 67.2188H14.7656C17.8593 61.3125 21.3281 55.6875 25.1719 50.3438H77.0625V33.1875H39.7969C46.0782 27 52.9688 21.375 60.4688 16.3125H77.0625V6.75C82.5938 4.125 88.2188 1.875 93.9375 0V16.3125H171.562V0C177.281 1.875 182.906 4.125 188.438 6.75V16.3125H205.031C212.531 21.375 219.422 27 225.703 33.1875H188.438V50.3438H240.328C244.172 55.6875 247.641 61.3125 250.734 67.2188ZM265.5 126.703C265.5 131.484 265.219 136.359 264.656 141.328H188.438V160.031H261C259.5 165.656 257.531 171.281 255.094 176.906H188.438V195.609H245.812C234.938 213.141 220.781 227.484 203.344 238.641C185.438 250.078 166.125 256.828 145.406 258.891V195.609H171.562V176.906H93.9375V195.609H120.094V258.891C99.375 256.828 80.0625 250.078 62.1562 238.641C44.7187 227.484 30.5625 213.141 19.6875 195.609H77.0625V176.906H10.4062C7.96873 171.281 6 165.656 4.5 160.031H77.0625V141.328H0.8438C0.281267 136.359 0 131.484 0 126.703C0 112.922 2.10937 99.5625 6.3281 86.625H120.094V141.328H93.9375V160.031H171.562V141.328H145.406V86.625H259.172C263.391 99.5625 265.5 112.922 265.5 126.703ZM248.625 124.453C248.531 117.422 247.734 110.438 246.234 103.5H162.281V124.453H248.625ZM171.562 50.3438V33.1875H93.9375V50.3438H171.562ZM209.953 212.484H162.281V238.641C180.094 233.766 195.984 225.047 209.953 212.484ZM103.219 124.453V103.5H19.2656C17.7656 110.438 16.9687 117.422 16.875 124.453H103.219ZM103.219 238.641V212.484H55.5469C69.5156 225.047 85.4063 233.766 103.219 238.641Z" fill="#A20202"/>
      </g>
      <defs>
        <clipPath id="clip0_6_3">
          <rect width="265.5" height="258.891" fill="white"/>
        </clipPath>
      </defs>
    </svg>
      </div>
      <div className="text-center font-medium text-2xl md:text-3xl text-zinc-950 relative text-nowrap">
        Splain This
      </div>
      <div className="transform scale-75 origin-left border items-center rounded-lg bg-gray-100 px-2 py-1 text-xs font-medium text-zinc-600">
        sigma
      </div>
    </div>
  );
};
