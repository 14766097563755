"use client";
import React from 'react';
import { Result } from "../result";
import { Search } from "../search";
import { Title } from "../title";
import { useParams } from "react-router-dom";
import background from '../../assets/bg.svg';
import {Helmet} from "react-helmet";

export default function SearchResultsPage() {
    let { query } = useParams();

    // You might use this `query` param to perform a search or modify the component's state

  return (
    <div className="absolute inset-0" style={{ backgroundImage: `url(${background})` }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{query != undefined && query != "" ? query : "splainthis.com - explain anything"}</title>
      </Helmet>
      <div className="mx-auto max-w-3xl absolute inset-4 md:inset-8 bg-white">
        <div className="h-20 pointer-events-none rounded-t-2xl w-full backdrop-filter absolute top-0 bg-gradient-to-t from-transparent to-white [mask-image:linear-gradient(to_bottom,white,transparent)]"></div>
        <div className="px-4 md:px-8 pt-6 pb-24 rounded-2xl ring-8 ring-zinc-300/20 border border-zinc-200 h-full overflow-auto">
          <Title query={query}></Title>
          <Result key={query} query={query} rid={query}></Result>
        </div>
        <div className="h-80 pointer-events-none w-full rounded-b-2xl backdrop-filter absolute bottom-0 bg-gradient-to-b from-transparent to-white [mask-image:linear-gradient(to_top,white,transparent)]"></div>
        <div className="absolute z-10 flex items-center justify-center bottom-6 px-4 md:px-8 w-full">
          <div className="w-full">
            <Search></Search>
          </div>
        </div>
      </div>
    </div>
  );
}