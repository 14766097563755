import React from "react";

export const Wrapper = ({ title, content }) => {
  return (
    <div className="flex flex-col gap-4 w-full">
      <div className="flex gap-2 text-red-600">{title}</div>
      {content}
    </div>
  );
};
