import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './globals.css';

// Import your components
import Home from "./components/home/home";
import SearchResultsPage from "./components/searchResultsPage/searchResultsPage";
import Error404Page from "./components/error404Page/error404Page";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search" element={<SearchResultsPage />} />
        {/* Dynamic route for search queries */}
        <Route path="/:query" element={<SearchResultsPage />} />
        <Route path="*" element={<Error404Page />} />
      </Routes>
    </Router>
  );
}

export default App;
