"use client";
import React from 'react';
import { Result } from "../result";
import { Search } from "../search";
import { Title } from "../title";
import { useParams, useLocation, Link } from "react-router-dom";
import background from '../../assets/bg.svg';
import {Helmet} from "react-helmet";

export default function Error404Page() {
    let { query } = useParams();
    let location = useLocation();

    let pathSegments = location.pathname.split('/').filter(Boolean);

    let errorMessage = "Error 404 page not found";

    if (pathSegments.length > 1) {
        let lastSegment = pathSegments[pathSegments.length - 1];
        errorMessage = (
            <span>
                error 404 page not found. Did you mean to go to <Link to={`/${lastSegment}`} className="text-blue-600">/{lastSegment}</Link>?
            </span>
        );
    }

  return (
    <div className="absolute inset-0" style={{ backgroundImage: `url(${background})` }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{query != undefined && query != "" ? query : "splainthis.com - explain anything"}</title>
      </Helmet>
      <div className="mx-auto max-w-3xl absolute inset-4 md:inset-8 bg-white">
        <div className="h-20 pointer-events-none rounded-t-2xl w-full backdrop-filter absolute top-0 bg-gradient-to-t from-transparent to-white [mask-image:linear-gradient(to_bottom,white,transparent)]"></div>
        <div className="px-4 md:px-8 pt-6 pb-24 rounded-2xl ring-8 ring-zinc-300/20 border border-zinc-200 h-full overflow-auto">
            {errorMessage}
        </div>
        <div className="h-80 pointer-events-none w-full rounded-b-2xl backdrop-filter absolute bottom-0 bg-gradient-to-b from-transparent to-white [mask-image:linear-gradient(to_top,white,transparent)]"></div>
      </div>
    </div>
  );
}