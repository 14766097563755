"use client";
import React from 'react';
import { RefreshCcw } from "lucide-react";
import { useNavigate } from 'react-router-dom';

function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-');
}

function deslugify(slug) {
  return decodeURIComponent(slug)
    .replace(/^\//, '')
    .replace(/-/g, ' ')
    .replace(/\w\S*/g, (txt) =>
      txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export const Title = ({ query }) => {
  const navigate = useNavigate();
  return (
    <div className="flex items-center pb-4 mb-6 border-b gap-4">
      <div
        className="flex-1 text-lg sm:text-xl text-black text-ellipsis overflow-hidden whitespace-nowrap"
        title={deslugify(query)}
      >
        {deslugify(query)}
      </div>
      <div className="flex-none">
        <button
          onClick={() => {
            navigate(slugify(query));
          }}
          type="button"
          className="rounded flex gap-2 items-center bg-transparent px-2 py-1 text-xs font-semibold text-red-600 hover:bg-zinc-100"
        >
          <RefreshCcw size={12}></RefreshCcw>Rewrite
        </button>
      </div>
    </div>
  );
};
